import {
  GetOrganizationInfoAccreditation,
  GetOrganizationInfoCredential,
} from "@platform-app/app/core/api/models";

export enum AccreditationCredentialType {
  Accreditation = "Accreditation Body",
  Credentialing = "Credentialing Body",
}

export interface AccreditationCredentialModel {
  id: string;
  type: AccreditationCredentialType;
  name: string;
}

export interface AccreditationCredentialUpdateModel {
  model:
    | GetOrganizationInfoAccreditation
    | GetOrganizationInfoCredential
    | null;
  type: AccreditationCredentialType;
}
